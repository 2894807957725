.screen-booking-schedule-detail {

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }

  .box-btn {

    margin: auto;
    padding: 30px 12px 0px;
    background-color: #fff;
  
    .ant-col-24 {
      display: flex;
      align-items: center;
  
      button {
        width: 100%;
        height: 40px;
        margin: auto;
      }
    }
  }
  
  .txt-total-minutes {
    color: red;
    font-style: italic;
  }
  
}

.position-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 42px;
  margin: auto;
  padding: 12px 0px;
  background-color: #fff;

  .ant-col-24 {
    display: flex;
    align-items: center;

    button {
      width: 80%;
      height: 45px;
      margin: auto;
    }
  }
}