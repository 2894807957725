.app-header {
  height: 50px;
  line-height: 50px;
  padding: 0;
  background: #001529;
  display: flex;
  justify-content: space-between;

  .collapsed-logo {
    height: 50px;
    line-height: 50px;

    .ant-btn {
      font-size: 16px;
      width: 50px;
      height: 50px;
      object-fit: contain;
      color: #fff;
    }

    // .logo {
    //   img {
    //     height: 45px;
    //     object-fit: contain;
    //   }
    // }
  }

  .lang-group {
    display: flex;
    align-items: center;
    flex-direction: row;

    strong {
      margin: 0px 5px;
      color: #fff;
    }
  }



  .flex-header {
    width: 100%;

    .icon-collapsed:hover {
      color: #fff;
    }

    .choose-format-date-time .ant-select-selector {
      width: 220px;
      color: red;
    }
  }

  .user-info {
    padding-right: 24px;
  }

}

.app-header-menu {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
}

.drawer-menu .ant-menu {
  height: 100%;
}

.drawer-menu .ant-drawer-body {
  padding: 0;
}

.drawer-menu .ant-drawer-header {

  background: #000c17;
  padding: 12px 0px;

  .logo {
    text-align: center;

    img {
      height: 64px;
      object-fit: contain;
    }
  }
}