@import "antd/dist/reset.css";
@import "./pages/app.scss";
@import "./pages/base.scss";
@import "./pages/custom-form-item.scss";
@import "./pages/page-common-error.scss";
@import "./pages/header.scss";
@import "./pages/loading.scss";
@import "./pages/custom-modal.scss";
@import "./pages/responsive.scss";
@import "./pages/min-with.scss";
@import "./pages/padding.scss";
@import "./pages/screen/screen-home.scss";
@import "./pages/screen/screen-booking-schedule.scss";

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: "Roboto";
  margin: 0;
  padding: 0;
  border: 0;
}

body,
html {
  font-family: Roboto;
  font-size: 14px;
  scroll-behavior: smooth;
  background: #fff;
}